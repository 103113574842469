
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import ApiService from "@/core/services/ApiService"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import $ from 'jquery'
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const current_tab = ref('' as any)
    current_tab.value = 'h5'

    const change_tab = (tab) => {
      current_tab.value = tab
    }
      
    const goToApprove = () => {
      router.push({ name: "approve" })
    }
      
    const goToHtml = (id) => {
      router.push({ name: "html", params: {eppcm_id: route.params.id, id: id} })
    }

    const goToHtmlLink = (item) => {
      router.push({ name: "approve_export_group_detail", params: {eppcm_id: route.params.id, id: item.id} })
    }

    const goToEppcm = () => {
      router.push({ name: "folder", params: {id: route.params.id} })
    }

    const onChange = (e) => {
      console.log('onChange')

      store
        .dispatch(Actions.UPLOAD_EXCEL, {
          file: e.target.files[0],
          id: route.params.id
        })
        .then(() => {
          Swal.fire({
            text: "上传成功",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })

          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const onUpload = () => {
      console.log('onUpload')
      $("#btn_upload").click()
    }

    const query = () => {
      store
        .dispatch(Actions.GET_EXPORT_GROUPS, {eppcm_id: route.params.id})
        .then(() => {
          export_groups.value = store.getters.currentExportGroups
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query()

    const export_groups = ref([] as any)

    const eppcm = ref({} as any)

    store
      .dispatch(Actions.GET_EPPCM, route.params.id)
      .then(() => {
        eppcm.value = store.getters.currentEppcm
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const onDelete = (id) => {
      Swal.fire({
        title: '确认删除？',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '确认',
        // denyButtonText: '取消',
        cancelButtonText: '取消'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_EXPORT_GROUP, id)
            .then(() => {
              query()
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
          // Swal.fire('Saved!', '', 'success')
        // } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }

    const onCreateExportGroup = () => {
      store
        .dispatch(
          Actions.CREATE_EXPORT_GROUP,
          {eppcm_id: route.params.id}
        )
        .then(() => {
          // folders.value = store.getters.currentEppcms
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const tags = ref([] as any)

    store
      .dispatch(Actions.GET_TAGS)
      .then(() => {
        tags.value = store.getters.currentTags
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      });

    const current_item = ref({} as any)

    const set_current_item = (item) => {
      current_item.value = item
    }

    const save = () => {
      store
        .dispatch(Actions.UPDATE_EXPORT_GROUP, {id: current_item.value.id, values: {...current_item.value}})
        .then(() => {
          Swal.fire({
            text: "保存成功",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })

          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    return {
      current_tab,
      change_tab,
      goToApprove,
      goToHtml,
      goToHtmlLink,
      onChange,
      onUpload,
      export_groups,
      eppcm,
      onDelete,
      goToEppcm,
      onCreateExportGroup,
      tags,
      save,
      current_item,
      set_current_item,
      ApiService,
    }
  }
})
